import React, { FC } from "react";
import { Intro } from "../components";
import { WithRootLayout } from "../hoc/withRootLayout";
import { useRouter } from "next/router";
import { translate } from "../utils";
import dynamic from "next/dynamic";

const Products = dynamic(
  () =>
    import("../components/modules/ProductsServices/ProductsServices").then(
      (component) => component.ProductsServices
    ),
  { ssr: false }
);

const About = dynamic(() =>
  import("../components/modules/AboutCompany/AboutCompany").then(
    (component) => component.AboutCompany
  )
);

const Mountains = dynamic(() =>
  import("../components/modules/MountainsBg/MountainsBg").then(
    (component) => component.MountainsBg
  )
);

const Interested = dynamic(() =>
  import("../components/modules/GetInterested/GetInterested").then(
    (component) => component.GetInterested
  )
);

const Footer = dynamic(() =>
  import("../components/modules/Contacts/Contacts").then(
    (component) => component.Contacts
  )
);

const Home: FC = (): JSX.Element => {
  const { locale } = useRouter();

  const introContent = {
    pageName: "home-page",
    title: translate(locale, "home_intro_heading"),
    description: translate(locale, "home_intro_description"),
  };

  const aboutCompanyContent = {
    title: "RION Energie GmbH",
    description: translate(locale, "rion_energe_gmbh_text"),
  };

  const GetInterestedContent = {
    title: translate(locale, "interest_content_title"),
    description: translate(
      locale,
      "interested_content_description_without_interest_sentence"
    ),
  };

  return (
    <div className="home">
      <Intro
        title={introContent.title}
        pageName={introContent.pageName}
        description={introContent.description}
        isGenegalPage
      />
      <Products />
      <About
        title={aboutCompanyContent.title}
        description={aboutCompanyContent.description}
        isHomePage
      />
      <Mountains />
      <Interested
        title={GetInterestedContent.title}
        description={GetInterestedContent.description}
      />
      <Footer />
    </div>
  );
};

export default WithRootLayout(Home);
